let baseUrl = "";
let frontEndUrl = "";
let plaidKey = "";
let plaidEnv = ""
// process.env.TZ = UTC
 baseUrl = "https://terra-api.cruzcontrol.io";
 frontEndUrl = "https://terra.cruzcontrol.io"
 plaidKey = "ae68f79fe745c7f843145765679f80"
 plaidEnv = "production"

// export const FRONT_END_ROUTE_ROUTE = `cruz.spericorn.com/`;
export const MAIN_ROUTE = `${baseUrl}/`;
export const API_ROUTE = `${baseUrl}`;
export const AUTH_ROUTE = `${baseUrl}/auth`;
export const FRONT_END_ROUTE_ROUTE = `${frontEndUrl}/`;
export const PLAID_KEY = plaidKey
export const PLAID_ENV = plaidEnv
